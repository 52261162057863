import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/react/toast/toast.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const LegacyPatternLink = makeShortcode("LegacyPatternLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/react/toast" storybook="https://workday.github.io/canvas-kit/?path=/story/components-popups-toast--basic" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=4268-86&t=UhTeJ6F4tDSMVJmL-4" package="@workday/canvas-kit-react" mdxType="PackageInfo" />
      <h2 {...{
        "id": "anatomy"
      }}>{`Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/23821993dfc73db4ad3d881453c6c805/536c7/component-anatomy-popup-toasts.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "22.972972972972975%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of a Toast Popup.",
              "title": "Image of a Toast Popup.",
              "src": "/static/23821993dfc73db4ad3d881453c6c805/50383/component-anatomy-popup-toasts.png",
              "srcSet": ["/static/23821993dfc73db4ad3d881453c6c805/1efb2/component-anatomy-popup-toasts.png 370w", "/static/23821993dfc73db4ad3d881453c6c805/50383/component-anatomy-popup-toasts.png 740w", "/static/23821993dfc73db4ad3d881453c6c805/536c7/component-anatomy-popup-toasts.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Container:`}</strong>{` Rectangular container that houses the icon and text for toasts.`}</li>
        <li parentName="ol"><strong parentName="li">{`Icon:`}</strong>{` An Icon is optional and indicates the status of the message being displayed.`}</li>
        <li parentName="ol"><strong parentName="li">{`Text:`}</strong>{` Description text for explaning what's going on with a process that just occurred.`}</li>
        <li parentName="ol"><strong parentName="li">{`Action Link:`}</strong>{` Optional link for directing users to find helpful information regarding the
action they just took.`}</li>
        <li parentName="ol"><strong parentName="li">{`Close Button:`}</strong>{` Close Button is optional and are provided when a Toast doesn't have a timer to
close itself.`}</li>
      </ol>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <ul>
        <li parentName="ul">{`Toasts should communicate information about the status of an application’s process, like
confirmation that a task has been successfully submitted.`}</li>
        <li parentName="ul">{`They are low-emphasis and meant to be temporary, but can also be dismissed manually with a Close
Button.`}</li>
        <li parentName="ul">{`Toasts should overlap and visually look like they are in front of other UI elements without
blocking important actions.`}</li>
        <li parentName="ul">{`Action Links should be short. Including a non-required action, such as “View More,” is optional.`}</li>
        <li parentName="ul">{`For more information on Toast placement, please reference the`}
          <LegacyPatternLink href="/patterns/notifications/" mdxType="LegacyPatternLink">Notification Pattern
Guidance</LegacyPatternLink>. For help deciding if a Toast is the best component for your use
case, reference this
          <LegacyPatternLink href="/patterns/notifications/#tab=usage&heading=deciding-what-to-use" mdxType="LegacyPatternLink">decision
tree</LegacyPatternLink>.
        </li>
      </ul>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When to Use`}</h3>
      <ul>
        <li parentName="ul">{`Use Toasts when communicating updates about the process of an application.`}</li>
        <li parentName="ul">{`Use Toasts for lower priority messages that do not require user action.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use-something-else"
      }}>{`When to Use Something Else`}</h3>
      <ul>
        <li parentName="ul">{`Consider using `}<a parentName="li" {...{
            "href": "/components/indicators/banner"
          }}>{`Banners`}</a>{` if communicating messages about system
errors or alerts.`}</li>
        <li parentName="ul">{`Consider using Dialogs when informing users about critical information that requires user action
or decision.`}</li>
      </ul>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Type`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Emphasis`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Purpose`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Toasts`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Although Toasts communicate low priority information, user action is still required to dismiss Toasts.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Banners`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Medium`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Banners should be used for messages about errors and alerts. They do not disappear unless the user resolves the required action.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Dialogs`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`High`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Dialogs are reserved for messages of the highest priority. They command user action.`}</td>
          </tr>
        </tbody>
      </table>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "accessibility-guidelines"
      }}>{`Accessibility Guidelines`}</h2>
      <h3 {...{
        "id": "how-toasts-impact-the-accessible-experience"
      }}>{`How Toasts Impact the Accessible Experience`}</h3>
      <p>{`Toasts can be easy for users to miss and they will not be the most robust way to send users
information. Toasts might dynamically appear and disappear outside of a low vision user’s magnified
view. Screen readers must announce passive toasts as they appear on screen in real-time, and users
must be able to navigate to an actionable toast without relying on a mouse pointer.`}</p>
      <p>{`Alert notifications should be reserved for time-sensitive and urgent information. Alerts are
disruptive, and are intended to interrupt screen reader speech output. Status notifications are
recommended for most cases because they will “politely” wait for users to finish what they are
doing.`}</p>
      <p>{`Refer to`}</p>
      <LegacyPatternLink href="/patterns/notifications/#tab=guidance&heading=accessibility-considerations" mdxType="LegacyPatternLink">Accessibility
Considerations in Notification Patterns</LegacyPatternLink> for more information.
      <h3 {...{
        "id": "keyboard-interaction"
      }}>{`Keyboard Interaction`}</h3>
      <p>{`Interactive elements in a Toast must have a focus indicator that is highly visible against the
background and against the non-focused state. Refer to
`}<a parentName="p" {...{
          "href": "/guidelines/accessibility/accessible-color"
        }}>{`Accessible Colors`}</a>{` for more information.`}</p>
      <p>{`User-generated actionable Toasts must move keyboard focus to the first interactive element inside
the Toast.`}</p>
      <p>{`Toast must support the following keyboard interactions:`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`Tab`}</inlineCode>{`: focus the close button or action link in the Toast`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Enter`}</inlineCode>{` or `}<inlineCode parentName="li">{`Space`}</inlineCode>{`: activate the close button or action link in the Toast`}</li>
      </ul>
      <h3 {...{
        "id": "screen-reader-interaction"
      }}>{`Screen Reader Interaction`}</h3>
      <p>{`Toast must communicate the following to users:`}</p>
      <ul>
        <li parentName="ul">{`Text content of the Toast when it appears in real-time`}</li>
      </ul>
      <h3 {...{
        "id": "design-annotations-needed"
      }}>{`Design Annotations Needed`}</h3>
      <ul>
        <li parentName="ul">{`Define notification type “status” or “alert”`}</li>
      </ul>
      <h3 {...{
        "id": "implementation-markup-needed"
      }}>{`Implementation Markup Needed`}</h3>
      <ul>
        <li parentName="ul">{`ARIA live regions work by dynamically inserting content updates inside of an element with the
`}<inlineCode parentName="li">{`aria-live`}</inlineCode>{` attribute set. For more information, refer to`}
          <LegacyPatternLink href="/patterns/notifications/#tab=guidance&heading=screen-reader-notifications" mdxType="LegacyPatternLink">Screen
Reader Notifications</LegacyPatternLink>.
        </li>
        <li parentName="ul">{`When an Actionable Toast is generated from a user action, set keyboard focus to the first
interactive element in the toast.`}</li>
        <li parentName="ul">{`When a toast is generated by the system, avoid moving the keyboard focus away from users’ context.
Use an ARIA live region instead.`}</li>
        <li parentName="ul">{`[Included in component]`}{` For time-sensitive notifications, set `}<inlineCode parentName="li">{`aria-live=”assertive”`}</inlineCode>{` and
`}<inlineCode parentName="li">{`role=”alert”`}</inlineCode>{` to interrupt screen reader users. Otherwise, set `}<inlineCode parentName="li">{`aria-live=“polite”`}</inlineCode>{` and
`}<inlineCode parentName="li">{`role=”status”`}</inlineCode>{` when the notification is not urgent.`}</li>
      </ul>
      <h2 {...{
        "id": "content-guidelines"
      }}>{`Content Guidelines`}</h2>
      <ul>
        <li parentName="ul">{`When writing Toasts, refer to the `}<a parentName="li" {...{
            "href": "/guidelines/content/ui-text/tooltips"
          }}>{`Tooltips and Toasts`}</a>{` section of the
Content Style Guide.`}</li>
      </ul>
    </TabPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      